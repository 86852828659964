<template>
  <tr>   
    <td class="justify-left">
      <v-btn outline small :disabled="!props.item.file" @click="openInvoice()">
        Abrir nota fiscal
      </v-btn>
    </td>
    <td class="justify-left">
      <strong style="font-size: 16px;">{{ props.item.number }}</strong>
    </td>   
    <td class="justify-left">
      <strong style="font-size: 16px;">{{ props.item.amount | money }}</strong>
    </td>
    <td class="justify-left">
      <strong>{{ props.item.emited_at | moment('DD/MM/YY HH:mm') }}</strong>
    </td>   
    <td class="justify-left">
      <strong>{{ props.item.service_date | moment('DD/MM/YY HH:mm') }}</strong>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'Invoices',
    methods: {
      open: function (url, style) {
        window.open(url, style)
      },
      createPayment: function () {
        console.log(this.config);
        this.$WiApiPost({
          uri: 'finance/payment',
          data: {
            finance_id: this.props.item.finance_id
          },
          callback: () => {
            this.config.refresh()
          },
          config: {
            msgAsk: true,
            msgLoad: true,
            msgSucess: true
          }
        })
      },
      updatePaymentStatus: function () {
        this.$WiApiPost({
          uri: `finance/payment/${this.props.item.id}/check`,
          callback: () => {
            this.config.refresh()
          },
          config: {
            msgAsk: {
              title: 'Atualizar o status?',
              text: 'Isso não poderá ser desfeito!'
            },
            msgLoad: true,
            msgSucess: true,
            msgError: true
          }
        })
      },
      openInvoice: function () {
        const invoice = this.props.item
        console.log(invoice)
        if (invoice?.file?.url) {
          window.open(invoice.file.url, '_blank').focus();
        }
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.expired {
  color: #F44336;
  background: #ffe7e5;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
  min-width: 44px !important;
  width: 44px !important;
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>